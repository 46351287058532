import { NEWS_CRITICALITIES } from '../constants'
import type { Timestamp } from './Common'

// This file defines types from server responses

export type VehicleLocation = {
  readonly type: 'unknown' | 'on-station' | 'between-stations'
  readonly station?: string
  readonly from?: string
  readonly to?: string
}

export type ContactLocation = {
  readonly type: 'unknown' | 'on-station' | 'on-vehicle'
  readonly personnelNumber: string
  readonly station?: string
  readonly vehicle?: number
  readonly trainNumber?: number
  readonly trainDepartureDate?: Timestamp
  readonly line?: string
  readonly location: ContactLocation
  readonly vehicleLocation?: VehicleLocation
  readonly timestamp: Timestamp
}

export type Contact = {
  firstName?: string
  lastName?: string
  personnelNumber?: string
  emailAddress?: string
  workPhoneNumber?: string
  crewTypeShortName?: string
  startLocation?: string
  endLocation?: string
  location?: ContactLocation
} & Omit<TaskContact, 'location'>

export type PhoneContact = {
  readonly phoneNumber: string
  readonly name: string
  readonly additionalInfo: string | null | undefined
  readonly searchKeys: string | null | undefined
}

export type PhoneContactGroup = {
  readonly name: string
  readonly additionalInfo: string
  readonly contacts: Array<PhoneContact>
  readonly searchKeys: string | null | undefined
}

export type Train = {
  number: string
  from: {
    station: string
    timestamp: Timestamp
  }
  to: {
    station: string
    timestamp: Timestamp
  }
}

export type Person = {
  name: string
  phone: string
  email: string
  trains: Train[]
}

export type ScheduleFeedbackEmphasis =
  | 'kiertosuunta'
  | 'vapaanmaara'
  | 'vapaatoiveet'
  | 'rytmitys'
  | 'tasapuolisuus'
  | 'muu'

export type ScheduleFeedbackError =
  | 'jaksonloppu'
  | 'lyhytvali'
  | 'tyovuoropuuttuu'
  | 'eipatevyytta'
  | 'poissaolovaarin'
  | 'muu'

export type ShiftFeedbackEmphasis =
  | 'lepo'
  | 'pituus'
  | 'sisalto'
  | 'sijoittuminen'
  | 'alkamisaika'
  | 'paattymisaika'
  | 'muu'

export type ShiftFeedbackError =
  | 'aloitusaika'
  | 'lopetusaika'
  | 'tehtavapuuttuu'
  | 'tehtavanajatvaarin'
  | 'ylimaarainentehtava'
  | 'muu'

export type FeedbackError = ScheduleFeedbackError | ShiftFeedbackError
export type FeedbackEmphasis = ScheduleFeedbackEmphasis | ShiftFeedbackEmphasis

export type FeedbackType = 'shift' | 'schedule'

export type InputFeedback = Readonly<{
  id: string
  shiftId?: string
  listId?: string
  type: FeedbackType
  stars: number
  requestAnswer: boolean
  emphasis: Array<FeedbackEmphasis>
  message: string
  errors: Array<FeedbackError>
  errorMessage: string
  response: string
  respondedAt: Timestamp | null | undefined
  read: boolean
}>

export type ReadState = 'unread' | 'read'

type BaseFeedback = {
  id: string
  type: FeedbackType
  stars: number
  requestAnswer: boolean
  emphasis: Array<FeedbackEmphasis>
  message: string
  errors: Array<FeedbackError>
  errorMessage: string
  response: string
  respondedAt: Timestamp | null | undefined
  loading: boolean
  error: string
}

type ShiftFeedback = BaseFeedback & {
  shiftId: string
  listId?: string
}

type ScheduleFeedback = BaseFeedback & {
  shiftId?: string
  listId: string
}

export type Feedback = Readonly<ShiftFeedback | ScheduleFeedback>

export type ResponseRead = Readonly<{
  id: string
  state: ReadState
  error: string
  loading: boolean
}>

export type PersonnelGroup = 'M' | 'J' | ''

export type User = Readonly<{
  ['number']: string
  actAsCommuter?: boolean
  actAsMaintenance?: boolean
  actAsServiceDriver?: boolean
  personnelGroup: PersonnelGroup | null | undefined
  serviceDriver: boolean
  originalNumber: string
  name: string
  familyName: string
  admin: boolean
  read_admin: boolean
  commuter_driver: boolean
  commuter_manager: boolean
  driver: boolean
  logistics_driver: boolean
  commuter_conductor: boolean
  conductor: boolean
  maintenance: boolean
  other: boolean
  loading: boolean
  error: string
}>

export type SignedIn = 'open' | 'signed-in'

export type Rest = {
  startTimestamp: string
  endTimestamp: string
}

export type InputShiftHead = {
  readonly id: string
  isCommuter?: boolean
  readonly shiftId: string
  readonly dayType: string
  readonly date: Timestamp
  readonly startDateTime: Timestamp
  readonly endDateTime: Timestamp
  readonly listStartDate: Timestamp
  readonly listEndDate: Timestamp
  readonly listId?: string
  readonly duration: Timestamp
  readonly preparation: string
  readonly wrapUp: string
  readonly signInStatus: SignedIn
  readonly rests?: Array<Rest>
}

export type InputSchedule = Readonly<{
  userNumber: string
  personnelGroup: PersonnelGroup
  serviceDriver: boolean
  from: Timestamp
  to: Timestamp
  shifts: Array<InputShiftHead>
  restDays: Array<RestDay>
}>

export type Schedule = {
  readonly id: string
  isCommuter?: boolean
  readonly userNumber: string
  readonly personnelGroup: PersonnelGroup
  readonly serviceDriver: boolean
  readonly from: Timestamp
  readonly to: Timestamp
  readonly shifts: Array<InputShiftHead>
  readonly restDays: Array<RestDay>
  readonly created_at: number
  readonly loading: boolean
  readonly error: string
}

export type TaskLocomotive = {
  order: number
  locId: string
  pulling: boolean
  pickUpLocation?: string
  dropOffLocation?: string
  remarks?: string
}

export type TaskContactRef = Readonly<{
  id: string
  number?: string
  role: string
  fromStation?: string
  toStation?: string
}>

export type TaskFormationTrain = {
  operatingDate: Timestamp
  trainNumber: string
  scheduledArrivalTime: Timestamp
  scheduledDepartureTime: Timestamp
  startLocationShortName: string
  commuterLineID: string
}

export type TaskFormation = {
  nextTrain: TaskFormationTrain
  orderNumber: number
  previousTrain: TaskFormationTrain
  vehicleNumberLong: string
  vehicleNumberShort: string
}

export type TaskSection = {
  sectionStartStationShortCode: string
  sectionStartStationScheduledTime: Timestamp
  sectionEndStationShortCode: string
  sectionEndStationScheduledTime: Timestamp
  formations: Array<TaskFormation>
  persons: Array<Contact>
}

export type CrewNoticeTrain = {
  lineId: string
  scheduledDepartureTime: Timestamp
  trainNumber: string
  departureDate: string
  departureStation: string
  scheduledArrivalTime: Timestamp
  arrivalStation: string
}

export type CrewNoticeAck = 'SEEN' | 'ACKNOWLEDGED'

export type CrewNotice = {
  personnelNumber: string
  trackNumbers: Array<string>
  occursAt: Timestamp
  sentAt: Timestamp
  id: string
  type: string
  trains: Array<CrewNoticeTrain> | null | undefined
  content: string
  crewNoticeId: string
  vehicleIds: Array<string> | null | undefined
  ack?: CrewNoticeAck
  eventAt: Timestamp
  closed?: boolean
  error?: string
  loading?: boolean
}

export type SmDisruptionMessage = {
  location: string
  resolvedReason: string | null
  initiatedReason: string
  resolvedTimestamp: Timestamp | null
  initiatedTimestamp: Timestamp
  initiatedReasonAdditionalText: string
  body: string
  title: string
  messageId: string
  isComplete: boolean
}

export type Task = Readonly<{
  id: number
  sequence: number
  taskName: string
  restTask: string
  instruction: string
  trainNumber: string
  trainNumberNumeric: string
  trainCategory?: string
  preparationMinutes?: number
  wrapUpMinutes?: number
  operatingDate?: string
  taskStartDateTime: Timestamp
  taskEndDateTime: Timestamp
  fromStation: string
  toStation: string
  departureTrack: string
  arrivalTrack: string
  previousArrival: string
  nextDeparture: string
  lockingInformation: string
  traction: string
  locomotives: Array<TaskLocomotive>
  contacts: Array<TaskContactRef>
  sections?: Array<TaskSection>
  leadingNotices?: Array<CrewNotice>
  trailingNotices?: Array<CrewNotice>
  independentNotices?: Array<CrewNotice>
}>

export type OperativeWagon = {
  type: string
  bookingNumber: number
  isIncluded?: boolean
}

export type SalesWagon = {
  type: string
  bookingNumber: number
  priority: number
}

export type AssemblyLeg = {
  lastUpdated: string
  startOcp: string
  endOcp: string
  salesWagons?: Array<SalesWagon>
  operativeWagons?: Array<OperativeWagon>
  arrival: Timestamp
  departure: Timestamp
}

export type Assembly = {
  timestamp: Timestamp
  id: string
  trainNumber: string
  operatingDay: string
  legs: Array<AssemblyLeg>
}

export type InputShift = {
  readonly id: string
  isCommuter?: boolean
  readonly dutyEvaluationPercentage?: number
  readonly shiftId: string
  readonly date: Timestamp
  readonly startDateTime: Timestamp
  readonly endDateTime: Timestamp
  readonly preparation: string
  readonly wrapUp: string
  readonly notifications: string
  readonly tasks: Array<Task>
  readonly error: string
  readonly listStartDate: string
  readonly listEndDate: string
  readonly listId?: string
  readonly duration: Timestamp
  readonly crewNotices?: Array<CrewNotice>
  readonly contacts: Array<Contact> | null | undefined
  readonly assemblies: Array<Assembly> | null | undefined
}

export type RestDay = Readonly<{
  dayType: 'W' | 'H'
  startDateTime: string
  endDateTime: string
  listStartDate: string
  listEndDate: string
  listId: string
}>

export type Shift = Readonly<{
  id: string
  dayType: string
  isCommuter?: boolean
  dutyEvaluationPercentage?: number
  date: Timestamp
  shiftId: string
  created_at: number
  startDateTime: Timestamp
  endDateTime: Timestamp
  scheduleStartTime?: Timestamp
  scheduleEndTime?: Timestamp
  notifications: string
  tasks: Array<Task> | null | undefined
  loading: boolean
  error: string
  listStartDate: string
  listEndDate: string
  listId?: string
  //duration from duty_details
  duration: Timestamp
  //duration from LoginSSO
  partDuration?: any
  //duration without rests
  workingTime?: number
  preparation: string
  wrapUp: string
  schedulePreparation?: string
  scheduleWrapUp?: string
  rests?: Array<Rest>
  crewNotices?: Array<CrewNotice>
  contacts: Array<Contact> | null | undefined
  assemblies: Array<Assembly> | null | undefined
  signInStatus?: string
}>

export type SearchedShift = Readonly<{
  trainNumber?: string
  date?: string
  ocp?: string
  shiftId?: string
  shifts: Array<Shift>
  error?: string
}>

export type SignInStatus = Readonly<{
  id: string
  state: SignedIn
  error: string
  loading: boolean
}>

export type TaskContact = Readonly<{
  ['number']?: string
  id?: string
  name?: string
  telephone?: string
  email?: string
  role?: string
  fromStation?: string
  toStation?: string
  location?: ContactLocation
}>

export type TaskDetailsInput = {
  isCommuter: boolean
  location?: string
  lockingInformation?: string
  station: string | null | undefined
  trains: Array<{
    trainNumber: string
    time?: Timestamp
    frame?: string
    lineId?: string
  }>
  type: string
}

export type TaskDone = Readonly<{
  id: string
  shiftId: string
  index: number
}>

export type Criticality = {
  level: string
  description: string
}

export type Fault = {
  faultCode: {
    code: string
    descriptions: Array<string>
  }
  faultType: string
  symptomDescriptions: Array<string>
  usageCode: string | null
  date: string
  criticality: Criticality
  description: string
  status: string
}

export type Finding = {
  equipment: {
    equipmentId: string
    reportingGroup: string
  }
  faults: Array<Fault>
}

export type TaskEquipment = {
  error: string | null | undefined
  findings: Array<Finding>
  loading: boolean
  highestCriticality: Criticality | null | undefined
}

export type TrainStop = {
  readonly station: string
  readonly timestamp: Timestamp
}

export type JWTToken = Readonly<{
  name: string
  familyName: string
  ['number']: string
  originalNumber: string
  admin: boolean
  read_admin: boolean
  commuter_driver: boolean
  commuter_manager: boolean
  driver: boolean
  logistics_driver: boolean
  commuter_conductor: boolean
  conductor: boolean
  maintenance: boolean
  other: boolean
  exp: number
  iat: number
}>

export type Session = User &
  Readonly<{
    created_at: number
    token: string
  }>

export type EventType = 'feedbackResponse' | 'pendingCrewNotice' | 'readCrewNotice'

export type Event = {
  id: string
  date: Timestamp
  type: EventType
  message: string
  read: boolean
  markAsReadText: string
  data?: any
}

export type PersonnelList = {
  conductors?: Array<Person>
  secondaryConductors?: Array<Person>
  drivers?: Array<Person>
  passengers?: Array<Person>
  unknowns?: Array<Person>
}

export type Personnel = {
  trainNumber: string
  depOcp: string
  arrOcp: string
  depTime: Timestamp
  arrTime: Timestamp
  personnel: PersonnelList
  contacts: Array<Contact>
}

export type Station = {
  stationAbbreviation: string
  countryCode: string
}

export type DangerousGood = {
  hazardNumber: string
  hazardLabel: string
  unno: string
  description: string
}

export type Car = {
  orderNumber: number
  carNumber: string
  carNumberShort: string | null | undefined
  brakeWeigth: number
  grossWeight: number
  kuljetusLaatu: string
  origStation: Station
  destinationStation: Station
  brakeWeight: string
  qualityOfTransport: string
  dangerousGoods: Array<DangerousGood>
  trackBrakeStatus: string
  cnCodes?: Array<string>
}

export type Locomotive = {
  orderNumber: number
  locomotiveNumber: string
  indPulling: boolean
  locTypeCode?: string
  brakeWeight?: string
  origStation?: {
    stationAbbreviation: string
  }
  destinationStation?: {
    stationAbbreviation: string
  }
  weight?: string
}

export type Route = {
  brakeRegime: string
  listTimestamp: string
  origStation: Station
  destinationStation: Station
  firstStation: Station
  lastStation: Station
  departureDate: string
  trainNumber: string
  trainType: string
  jarruLaji: string
  listStatus: string
  status: string
  brakeConditionCheckCompleted: string
  trafficInspectionCompleted: string
  smallBrakeCheckCompleted: string
  maximumSpeedReduced: string
  routeParts: [
    {
      origStation: Station
      destinationStation: Station
      axel: number
      carsTons: number
      engineCount: number
      engineSeries: string
      length: number
      tons: number
      brakeWeight: number
      brakeWeightPercent: number
      estBrakeWeight: number
      estBrakeWeightPercente: number
      pt: string
    },
  ]
  cars: Array<Car>
  locomotives: Array<Locomotive>
}

export type Compositions = {
  sourceSystem: string
  resultCode: string
  resultDescription: string
  departureTrack: number
  warningNotes: string
  noteList: Array<string>
  route: Route
  locInformation: string
  trainNumber: string
  date: string
  station: string
  tractionPower: string
  timestamp: string
  maxSpeed: string
}

export type HandlingStation = {
  ocpCode: string
  arrivalTime: string
  departureTime: string
  handlingSpot: boolean
  departureCancelled: boolean | null | undefined
  arrivalCancelled: boolean | null | undefined
}

export type HandlingStations = {
  ocpTts: HandlingStation[]
}

export type Modal = {
  open: boolean
  message: string
  confirmCompleted?: string
  confirmType?: string
  action?: string
  locomotives?: Array<Locomotive>
  sourceSystem?: string
  secondAction?: string
}

export type TimetableParams = {
  trainNumber: string
  timetableDate: string
  depStation?: string
  depTime?: string
  arrStation?: string
  arrTime?: string
}

export type CheckedModal = {
  open: boolean
  message: string
  checked: boolean
}

export type SearchDeleteParams = {
  trainNumber?: string
  date?: string
  station?: string
  shiftId?: string
}

export type ReasonCode = {
  level1Code: string
  level2Code: string | null | undefined
}

export type AmendmentChallengeType = 'CHALLENGE' | 'AMEND'

export type AmendmentChallengeStatus = 'CREATED' | 'SUBMITTED' | 'ACCEPTED' | 'REJECTED'

export type AmendmentChallenge = {
  status: AmendmentChallengeStatus
  originalReasonCode: ReasonCode
  amendedReasonCode: ReasonCode
  type: AmendmentChallengeType
  creationTimestampUtc: Timestamp
}

export type DelayCause = {
  categoryCodeId: string
  categoryCode: string
  categoryName: string
  amendmentChallenge?: AmendmentChallenge
}

export type Delay = {
  causes?: DelayCause[]
}

export type TimetableRow = {
  trainStopping: boolean
  stationShortCode: string
  stationcUICCode: number
  countryCode: string
  type: string // “ARRIVAL” or “DEPARTURE”,
  cancelled: boolean
  scheduledTime: string
  liveEstimateTime: string | null | undefined
  estimateSource: string | null | undefined
  unknownDelay: boolean
  actualTime: string
  differenceInMinutes: number
  causes: Array<DelayCause>
  commercialTrack: string
}

export type TrainPunctuality = {
  trainNumber: number
  departureDate: string
  trainType: string
  trainCategory: string
  runningCurrently: boolean
  cancelled: boolean
  version: number
  timetableType: string // REGULAR or ADHOC,
  timetableAcceptanceDate: string
  deleted?: boolean
  timeTableRows: Array<TimetableRow>
  updatedAt: number
}

export type MappedPunctuality = {
  estimate: boolean
  notDriving: boolean
  stationShortCode: string
  scheduledDepartureTime: string | null | undefined
  actualDepartureTime: string | null | undefined
  differenceDepartureMinutes: number
  scheduledArrivalTime: string | null | undefined
  actualArrivalTime: string | null | undefined
  differenceArrivalMinutes: number
  departureCauses: Array<DelayCause> | undefined
  arrivalCauses: Array<DelayCause> | undefined
}

export type TracksFromPunctuality = {
  departureTrack: string
  arrivalTrack: string
}

export type SecondLevelCause = {
  description: string
  responsible: string
  askRelatedTrainNumber: boolean
}

export type FirstLevelCause = {
  description: string
  secondLevelCodes: Record<string, SecondLevelCause>
}

export type CauseGroup = {
  description: string
  amendable?: boolean
  firstLevelCodes: Record<string, FirstLevelCause>
}

export type Causes = Record<string, CauseGroup>

export type FlattenedCause = {
  code?: string
  description?: string
  level?: number
  parent?: string | null | undefined
  responsible?: string | null | undefined
  causeGroupKey?: string
  hasSubCodes?: boolean
  amendable?: boolean
  askRelatedTrainNumber?: boolean
}

export type FlattenedCauses = Record<string, FlattenedCause>

export type ObservationMessageReference = {
  type: string
  trainNumber?: string
  operatingDate?: Timestamp
  externalId?: string
  dateTime?: Timestamp
}

export type ObservationMessage = {
  title: string
  description?: string
  personnelNumber: string
  dutyShortName: string
  dutyDate: Timestamp
  messageDateTime: Timestamp
  reference?: ObservationMessageReference
}

export type TestPushMessage = {
  messageId: string
  sentAt: Timestamp
  receivedAt?: Timestamp
}

export type TowingFormContentTimestamped = TowingFormContent & { received_at: number }

export type TowingFormContent = {
  // Placeholder type.
  // Unclear wether typing out the whole sum type with all possible fields is productive
  id: string
  name?: string
  updatedAt?: Timestamp
  contentType?: string
  equipmentType: string
  setupRoot?: any
  startRoot?: any
  stopRoot?: any
  finishRoot?: any
}

export type TowingStep = {
  id: string
  contentType: string
  fields: any
}

export type TowingFormStateChange = {
  status: string
  changedBy: string
  changedByPersonnnelNumber: string
  changedAt: Timestamp
}

export type TowingFormStepOrSelection = Record<string, string>

export type TowingFormState = {
  created_at?: Timestamp
  id: string
  lastSaved: Timestamp
  vehicleType: string
  vehicleNumber: string
  contentfulId: string
  actionsAndSelectionsByKey: TowingFormStepOrSelection
  stateChanges: Array<TowingFormStateChange>
}

export type VehicleStateChange = {
  status: string
  updatedAt: Timestamp
  updatedBy: {
    personnelNumber: string
    name: string
  }
}

export type TowingVehicle = {
  vehicleCompound: string
  vehicleType: string
  vehicleNumber: string
  rawVehicleNumber?: string
  activeFormId: string | null | undefined | null
  vehicleStates: Array<VehicleStateChange>
  isDummy: boolean | null | undefined
}

export type TowingAuditMessage = {
  action: string
  formId: string
  changeDateTime: Timestamp
  id: string
}

export type TowingVehiclePattern = {
  name: string
  expression: string
  hint: string
  description: string
  formName: string
  userInputExpression: string
  equipmentType: string
}

export type TowingSearch = {
  formName: string
  vehicleNumber: string
  vehicleType: string
}

export type DutyEvaluation = {
  timestamp: Timestamp
  shiftId: string
  shiftDate: string
  personnelNumber: string
  evaluationPercentage: number
}

export type EnergyEfficiencyComparativeData = {
  median: number | null | undefined
  min: number | null | undefined
  pctl20: number | null | undefined
  pctl40: number | null | undefined
  pctl60: number | null | undefined
  pctl80: number | null | undefined
  max: number | null | undefined
  n: number | null | undefined
  method: string | null | undefined
  desc: string | null | undefined
}

export type EnergyEfficiencyLeg = {
  trainNumber: string
  depStation: string
  arrStation: string
  depScheduledDt: string
  arrScheduledDt: string
  depActualDt: string
  arrActualDt: string
  departureDate: string | null | undefined
  massInTons: number | null | undefined
  equipment: string | null | undefined
  equipmentType: string | null | undefined
  pullingUnits: number | null | undefined
  allUnitsMeasured: boolean | null | undefined
  measuredConsumptionInKWh: number | null | undefined
  correctedConsumptionInKWh: number | null | undefined
  comparativeData: EnergyEfficiencyComparativeData | null | undefined
  updatedDt: string | null | undefined
}

export type EnergyEfficiencyShift = {
  shiftId: string
  shiftDate: string
  legs: Array<EnergyEfficiencyLeg>
}

export type EnergyEfficiency = {
  shifts: Array<EnergyEfficiencyShift>
  latestComparativeData: EnergyEfficiencyComparativeData | null | undefined
}

export type RollingGuideData = Record<string, string>

export type ShiftNotice = {
  noticeText: string
  criticality: 'critical' | 'warning' | 'info'
}

export type News = {
  identifier: string
  type: string
  title: string
  category: keyof typeof NEWS_CRITICALITIES | string
  linkToItem: string
  timestamp: string
}

/* Defects */
export type ActionStatus = 'loading' | 'succeeded' | 'failed' | 'none'

export type Defect = {
  actualRepairAction: string
  affectToUsage: string
  comments: Array<{ comment: string; date: string }>
  criticality: string
  defectCode: string
  defectCodeDescription: string
  defectDescription: string
  defectGroup: string
  defectType: string
  id: string
  installationGroup: string
  installationItem: string
  installationItemDescription: string
  issuedTime: string
  repairComment: string
  repairCompletionTime: string
  restrictionCodeDescription: string
  station: string
  status: string
  trafficDisturbance: boolean
  trafficDisturbanceConsequence: string
  vehiclePosition: string
}

export type DefectsFilter = {
  installationGroups: string
  token?: string
}

export enum StepState {
  DONE = 'DONE',
  COUNTERED_IN_SETUP = 'COUNTERED_IN_SETUP',
  COUNTERED_IN_FINISH = 'COUNTERED_IN_FINISH',
  REVERSED = 'REVERSED',
  CLEARED = 'CLEARED',
}

export type ContactProps = TaskContact | Contact
